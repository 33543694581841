<template>
    <div class="search">
        <section>
            <div class="container">
                <div class="not-found">
                    <div class="icon"></div>
                    <h1>¡Ups!</h1>
                    <h2>Eso que estás buscando<br>no está aquí</h2>
                </div>
            </div>
        </section>
        <section class="news">
            <div class="container">
                <div class="section-header">
                    <h2 class="section-title">Podría interesarte</h2>
                </div>
                <div class="section-content">
                    <div class="col m4 s6 l3" v-for="newsItem in relatedNews.data" :key=newsItem.id>
                        <NewsItem :item="newsItem" />
                    </div>
                </div>
            </div>
        </section>    
    </div>
</template>

<script>
    import NewsItem from '@/components/NewsItem.vue'

    export default {
        name: 'NotFound',
        components:{
            NewsItem,
        },
        data(){
            return {
                isLoading: true,
                relatedNews: []
            }    
        },
        mounted (){
            (async () => {
                const category = this.$route.params.category

                this.setHeader('page', {
                    titulo: '404'
                })

                this.isLoading = true
                this.relatedNews = await this.getItems('noticias', 8, 'sort=-visualizaciones')
                this.isLoading = false
            })()
        }
    }

    

</script>


<style scoped>
.not-found{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.not-found h1, .not-found h2{
    color: black;
}

.icon {
    background: #aaaa;
    mask: url("/assets/img/error-404.svg");
    mask-size: 80px;
    width: 80px;
    height: 80px;
    mask-repeat: no-repeat;
    mask-position: center;
  }
</style>